/* eslint-disable react/display-name */
import React, { useRef, forwardRef } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import Link from 'components/shared/Link'
import classnames from 'classnames/bind'
import useSpring from 'hooks/useSpring'
import useSetStyle from 'hooks/useSetStyle'
import { gsap } from 'gsap'

import useOnMouseEnter from 'hooks/useOnMouseEnter'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const Button = forwardRef(
  (
    {
      className,
      theme,
      border,
      link,
      size,
      tagName,
      disabled,
      fullWidth,
      buttonClassName,
      buttonWrapperClassName,
      withFocus,
      id,
      ...rest
    },
    ref
  ) => {
    const [mouseIsHover, onMouseEnter, onMouseLeave] = useOnMouseEnter(false)

    const shadowRef = useRef()
    const buttonRef = useRef()
    const hoverCircle = useRef()

    const setShadowStyle = useSetStyle(shadowRef)
    const setButtonStyle = useSetStyle(buttonRef)
    const setHoverCircleStyle = useSetStyle(hoverCircle)

    const [setTargetValue] = useSpring({
      opacity: 0,
      z: 0,
      config: {
        friction: 25,
        rigidity: 0.13,
      },
      onUpdate: ({ opacity, z }) => {
        setShadowStyle({ opacity })
        setButtonStyle({ z, transformPerspective: 800 })
      },
    })

    const [setCircleValue] = useSpring({
      progress: 0,
      config: {
        interpolation: 'basic',
        friction: 10,
      },
      onUpdate: ({ progress }) => {
        setHoverCircleStyle({
          transformPerspective: 15,
          z: -90 + 100 * progress,
          opacity: gsap.utils.wrapYoyo(0, 0.5, progress),
        })
      },
    })

    useIsomorphicLayoutEffect(() => {
      setTargetValue({
        opacity: mouseIsHover ? 1 : 0,
        z: mouseIsHover ? 43 : 0,
      })
      setCircleValue({
        progress: mouseIsHover ? 1 : 0,
        config: {
          friction: mouseIsHover ? 7.5 : 1,
        },
      })
    }, [mouseIsHover])

    const Component = tagName || Link

    return (
      <div
        ref={ref}
        id={id}
        className={cx(className, size, css.buttonContainer, {
          disabled,
          withFocus,
          fullWidth,
        })}
      >
        <div
          ref={ref}
          className={cx(css.button, theme, buttonWrapperClassName, {
            fullWidth,
          })}
        >
          <span ref={shadowRef} className={css.shadow} />
          <div ref={buttonRef} className={cx(css.background, theme, border)}>
            <div ref={hoverCircle} className={css.hoverCircle} />
          </div>
          <Component
            className={cx(css.buttonLink, buttonClassName)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={onMouseEnter}
            onBlur={onMouseLeave}
            {...link}
            target={link?.target ?? '_self'}
            {...rest}
          />
        </div>
      </div>
    )
  }
)

export default Button
