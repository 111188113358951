import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import classnames from 'classnames/bind'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const useUTMParams = () => {
  const { asPath } = useRouter()
  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'ref',
  ]
  let params = {}

  // Extract UTM parameters from the URL
  utmParams.forEach((param) => {
    const match = new RegExp('[?&]' + param + '=([^&]*)').exec(asPath)
    if (match && match[1]) {
      params[param] = match[1]
    }
  })

  return params
}

const useCorrectHref = (hrefObj) => {
  const router = useRouter()

  // Check if we're in the marketplace context and adjust the URL accordingly
  const isMarketplace =
    router.route.startsWith('/marketplace') &&
    !hrefObj.pathname.startsWith('/marketplace')
  let basePath = isMarketplace ? 'https://strapi.io' : ''

  // Preparing the URL object
  let url = {
    pathname: basePath + hrefObj.pathname,
    query: {},
  }

  // If there's no need to adjust the domain or no UTM parameters, return the original hrefObj
  if (!isMarketplace && Object.keys(url.query).length === 0) {
    return hrefObj
  }

  return url
}

const CustomLink = (props) => {
  const {
    className,
    children,
    href = '#',
    alt = '',
    target = '',
    text,
    id,
    replace,
    shallow,
    scroll,
    forwardRef,
    external,
    contentType,
    as,
    ...rest
  } = props

  const hrefObj =
    typeof href === 'string'
      ? {
          pathname: href || '/',
          query: {},
        }
      : href || { pathname: '/', query: {} }

  const isAnchor = hrefObj.pathname.startsWith('#')

  const isExternal = !!(hrefObj.pathname.indexOf('http') === 0 || external)

  const utmParams = useUTMParams()

  Object.keys(utmParams).forEach((key) => {
    hrefObj.query[key] = utmParams[key]
  })

  if (hrefObj.pathname.includes('?')) {
    const splittedHref = hrefObj.pathname.split('?')
    hrefObj.pathname = splittedHref[0]
    const query = splittedHref[1]

    const params = query.split('&')
    params.forEach((param) => {
      const [key, value] = param.split('=')
      hrefObj.query[key] = value
    })
  } 

  if (isExternal || isAnchor) {
    // Handle external links with UTM persisting + already existing query params

    return (
      <Link href={hrefObj} legacyBehavior>
        <a
          {...rest}
          id={id}
          className={cx(css.Link, className)}
          target={target || '_blank'}
          rel="noopener"
          alt={alt}
          ref={forwardRef}
        >
          {children || text}
        </a>
      </Link>
    )
  }

  const hrefWithCorrectDomain = useCorrectHref(hrefObj)

  return (
    <Link
      href={hrefWithCorrectDomain}
      shallow={shallow}
      scroll={scroll}
      replace={replace}
      as={as}
      legacyBehavior
    >
      <a
        className={cx(css.Link, className)}
        ref={forwardRef}
        target={target}
        alt={alt}
        {...rest}
      >
        {children || text}
      </a>
    </Link>
  )
}

export default React.forwardRef((props, ref) => (
  <CustomLink {...props} forwardRef={ref} />
))
