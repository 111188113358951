import React, { useRef, useCallback } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import delve from 'dlv'
import classnames from 'classnames/bind'
import useSetStyle from 'hooks/useSetStyle'

import useSpring from 'hooks/useSpring'
import useOnResize from 'hooks/useOnResize'
import { gsap } from 'gsap'

import typo from 'components/typo/typography.module.scss'
import Push from './Push'
import LinksSection from './LinksSection'
import ArrowSection from './ArrowSection'
import styles from './styles.module.scss'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const defaultInterpolationConfig = { interpolation: 'basic', friction: 3 }

const interpolationConfig = {
  transition: defaultInterpolationConfig,
  opening: { ...defaultInterpolationConfig, friction: 9 },
  closing: { ...defaultInterpolationConfig, friction: 1 },
}

const Panel = ({
  className,
  sections,
  pushs,
  onLinkClick,
  isVisible,
  setPanelRect,
  panelId,
  arrowLinks,
  panelStatus,
}) => {
  const panelRef = useRef()
  const cardRef = useRef()
  const linksRef = useRef()
  const pushsRef = useRef()

  const setPanelStyle = useSetStyle(panelRef)
  const [setPanelSpring] = useSpring({
    progress: 0,
    config: defaultInterpolationConfig,
    onUpdate: ({ progress }) => {
      setPanelStyle({
        autoAlpha: gsap.utils.normalize(
          panelStatus === 'opening' ? 0.6 : 0,
          1,
          progress
        ),
        y: (1 - progress) * -30,
      })
    },
  })

  const [setPanelYSpring] = useSpring({
    progress: 0,
    config: {
      friction: 90,
      rigidity: 0.12,
    },
    onUpdate: ({ progress }) => {
      setPanelStyle({
        y: panelStatus === 'opening' ? (1 - progress) * -35 : 0,
        z: panelStatus === 'opening' ? (1 - progress) * -30 : 0,
        transformPerspective: 1000,
      })
    },
  })

  const onResize = useCallback(() => {
    if (cardRef.current && setPanelRect) {
      const wrapper = cardRef.current.getBoundingClientRect()
      const content = linksRef.current.getBoundingClientRect()
      setPanelRect(panelId, wrapper, content)
    }
  }, [cardRef.current, panelId])

  useOnResize(onResize)

  useIsomorphicLayoutEffect(() => {
    setPanelSpring({
      config: interpolationConfig[panelStatus],
      progress: isVisible ? 1 : 0,
    })
    setPanelYSpring({ progress: isVisible ? 1 : 0 })
  }, [panelId, isVisible, panelStatus])

  return (
    <div ref={panelRef} className={cx(css.Panel, className)}>
      <div ref={cardRef} className={css.card}>
        <div ref={linksRef} className={cx(css.linksWrapper, { isVisible })}>
          <div
            className={
              pushs?.length > 0 ? css.linkSectionsHasPush : css.linkSections
            }
          >
            {sections.map((section, id) => {
              return (
                <LinksSection
                  key={id}
                  title={section.title}
                  links={section.links}
                  onLinkClick={onLinkClick}
                />
              )
            })}
          </div>
            {arrowLinks &&
              arrowLinks.length > 0 &&
              arrowLinks.map((arrowLink) => {
                const id = delve(arrowLink, 'id')
                const text = delve(arrowLink, 'text')
                const title = delve(arrowLink, 'title')
                const icons = delve(arrowLink, 'icons')

                return (
                  <ul className={css.arrowLinks}>
                    <ArrowSection
                      key={id}
                      title={title}
                      text={text}
                      icons={icons}
                      link={arrowLink}
                      onClick={onLinkClick}
                    />
                  </ul>
                )
              })}
        </div>
        {pushs && pushs.length > 0 && (
          <div ref={pushsRef} className={css.pushs}>
            {pushs.map((push, id) => {
              return (
                <Push
                  key={id}
                  onLinkClick={onLinkClick}
                  image={push.image}
                  title={push.title}
                  link={push.link}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Panel
