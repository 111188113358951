import React, { createContext, useContext, useEffect, useState } from 'react'

export const HasStickyBarProviderContext = createContext([{}, () => {}])

export default function HasStickyBarProviderProvider({
  children,
  initialHasStickyBar = false,
}) {
  const useHasStickyBarProviderState = useState(initialHasStickyBar)
  
return (
  <HasStickyBarProviderContext.Provider value={useHasStickyBarProviderState}>
    {children}
  </HasStickyBarProviderContext.Provider>
  )
}

export function useHasStickyBarProviderContext() {
  return useContext(HasStickyBarProviderContext)
}

export function useRegisterStickyBar() {
  const [, setHasStickybar] = useHasStickyBarProviderContext()

  useEffect(() => {
    setHasStickybar(true)
    
return () => {
      setHasStickybar(false)
    }
  }, [])
}
