import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useTimeline from 'hooks/useTimeline'
import { useInView } from 'react-intersection-observer'
import delve from 'dlv'
import classnames from 'classnames/bind'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const Img = React.forwardRef(
  (
    { src, alt, media, loading = 'lazy', fit, position, className, format },
    ref
  ) => {
    const imgElement = useRef()
    const imgRef = useRef()
    const [isLoaded, setIsLoaded] = useState()
    const shouldLazyLoad = useMemo(() => loading === 'lazy', [loading])

    const mediaSrc = delve(media, 'url') || delve(media, 'data.attributes.url')

    let imageUrl

    if (src) {
      imageUrl = src
    } else if (mediaSrc) {
      const isExternal = mediaSrc.indexOf('http') === 0
      const mediaSrcWithFormat = delve(
        media,
        `formats[${format}].url`,
        mediaSrc
      )

      imageUrl = isExternal
        ? mediaSrcWithFormat
        : `${process.env.IMAGE_URL}${mediaSrcWithFormat}`
    } else {
      imageUrl = ''
    }

    const animateIn = useTimeline({ paused: true }, (tl) => {
      tl.fromTo(
        imgRef.current,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          ease: 'none',
          duration: 0.3,
        }
      )
    })

    const [inViewRef, inView] = useInView({
      triggerOnce: true,
      skip: !shouldLazyLoad,
    })

    useIsomorphicLayoutEffect(() => {
      if (
        (loading === 'lazy' && inView && isLoaded) ||
        (loading !== 'lazy' && isLoaded)
      ) {
        animateIn.play(0)
      }
    }, [inView, isLoaded])

    const lazyLoadedSrc = useMemo(() => {
      if (!shouldLazyLoad || (inView && shouldLazyLoad)) {
        return imageUrl
      }

      return ''
    }, [shouldLazyLoad, imageUrl, inView])

    const setRefs = useCallback(
      (node) => {
        inViewRef(node)
        ref && (ref.current = node)
        imgRef.current = node
      },
      [inViewRef]
    )

    const hasLoaded = useCallback(() => setIsLoaded(true), [])

    useEffect(() => {
      imgElement.current = new Image()

      return () => {
        imgElement.current = null
      }
    }, [])

    useEffect(() => {
      if (lazyLoadedSrc) {
        setIsLoaded(false)
        imgElement.current.src = lazyLoadedSrc
        imgElement.current.decode
          ? imgElement.current
              .decode()
              .then(hasLoaded)
              .catch((err) => console.error(err))
          : (imgElement.current.onload = hasLoaded)
      }
    }, [lazyLoadedSrc])

    return (
      <img
        className={cx(css.Img, className, { [fit]: true, [position]: true })}
        ref={setRefs}
        src={hasLoaded ? lazyLoadedSrc : null}
        alt={alt || media?.name}
      />
    )
  }
)

Img.defaultProps = {}

export default Img
