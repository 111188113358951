import Image from 'next/image'
import React, { Fragment, useState } from 'react'
import { useWindowSize } from 'react-use'

import Link from 'components/shared/Link'
import { Text, Title } from 'components/typo'
import LinkItem from '../../Desktop/Panel/LinksSection/LinkItem'

import css from './styles.module.scss'

const NavigationPanel = ({
  navigationLabel,
  sections,
  arrowLinks,
  onClick,
}) => {
  const { width } = useWindowSize()
  const [hoverLinkId, setHoverLinkId] = useState(null)

  const renderLinks = (link) => {
    if (!link) return null

    return (
      <Fragment key={link.id}>
        {width <= 425 ? (
          <Link
            className={css.link}
            onClick={onClick}
            target={link.target}
            href={link.href}
            contentType={link.contentType}
          >
            {link?.icons?.length > 0 && (
              <Image
                src={`${process.env.API_URL}${link.icons[0]?.media?.data?.attributes?.url}`}
                alt={link.icons?.[0]?.alt}
                width={20}
                height={20}
              />
            )}
            <Text
              className={css.linkLabel}
              tag="span"
              size="smaller"
              key={`link-${link.id}`}
            >
              {link.text}
            </Text>
            {/* <span className={css.linkLoadingText}>Coming soon...</span> */}
          </Link>
        ) : (
          <LinkItem
            id={link.id}
            hoverLinkId={hoverLinkId}
            text={link.text}
            summary={link.summary}
            icons={link.icons}
            link={link}
            onMouseEnter={() => setHoverLinkId(link.id)}
            onMouseLeave={() => setHoverLinkId(null)}
            onLinkClick={onClick}
          />
        )}
      </Fragment>
    )
  }

  return (
    <li className={css.section}>
      <Title tag="p" className={css.title} size="small">
        {navigationLabel &&
          (navigationLabel.href ? (
            <Link
              href={navigationLabel.href}
              target={navigationLabel.target}
              contentType={navigationLabel.contentType}
            >
              {navigationLabel.text}
            </Link>
          ) : (
            <span>{navigationLabel.text || navigationLabel}</span>
          ))}
      </Title>
      {sections &&
        sections.length > 0 &&
        sections.map((section) => (
          <ul key={`section-links-${section.id}`} className={css.links}>
            {section.links.map(renderLinks)}
          </ul>
        ))}
      {arrowLinks && arrowLinks.length > 0 && (
        <ul className={css.links}>{arrowLinks.map(renderLinks)}</ul>
      )}
    </li>
  )
}

export default NavigationPanel
