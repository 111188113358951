import React from 'react'
import { useCopyToClipboard } from 'react-use'

import classnames from 'classnames/bind'

import Layout from 'components/shared/Layout'
import Button from 'components/shared/Button'
import Image from 'components/shared/Image'
import Code from 'components/shared/Code'

import { Title } from 'components/typo'

import footerTopRight from './footer-top-right.svg'
import footerTopRightDarkMode from './footer-top-right-dark-mode.svg'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const FullWidthCTABanner = ({
  title,
  buttons,
  className,
  cliCommand,
  darkMode,
}) => {
  const [_, copyToClipboard] = useCopyToClipboard()
  
return (
  <div className={cx(css.FullWidthCTABanner, className, { darkMode })}>
    <Image
      className={css.background}
      media={{ width: 298, height: 300 }}
      src={darkMode ? footerTopRightDarkMode : footerTopRight}
    />

    <Layout type="maxWidth">
      <Layout type="wrapper">
        <Layout type="innerWrapper">
          {title && (
          <Title className={css.title} theme={darkMode ? 'white' : 'dark'}>
            {title}
          </Title>
            )}
          {buttons && (
          <div
            className={
                  css.actionsWithoutCli
                }
          >
            {buttons.map((button) => {
                  return (
                    <div key={button.id} className={css.button}>
                      <Button key={button.id} {...button} />
                    </div>
                  )
                })}
          </div>
            )}
          {cliCommand && (
          <button
            id="code-banner"
            className={css.code}
            onClick={() => copyToClipboard(cliCommand)}
          >
            <Code
              className={css.codeBlock}
              theme="purple"
              content={cliCommand}
              noMargin
            />
          </button>
            )}
        </Layout>
      </Layout>
    </Layout>
  </div>
  )
}

FullWidthCTABanner.defaultProps = {}

export default FullWidthCTABanner
