import { useEffect } from 'react'
import useScript from 'hooks/useScript'

const SponsoredScript = () => {
  const attributes = {
    'data-advertiser-id': '12',
    'data-event-type': 'pageView',
    'data-cookieconsent': 'statistics',
    'data-sponsored-script': true,
  }
  const [, error] = useScript({
    src: 'https://app.sponsored.us/analytics.js',
    attributes,
    removeScriptOnCleanup: true,
  })

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return null
}

export default SponsoredScript
