import React, { useState } from 'react'
import { useRouter } from 'next/router'

import classnames from 'classnames/bind'

import Logo from 'components/shared/Logo'
import Button from 'components/shared/Button'
import Layout from 'components/shared/Layout'
import Overlay from './Overlay'
import HeaderBackground from '../HeaderBackground'
import { ReactComponent as BurgerIcon } from './burger-icon.svg'
import { ReactComponent as Search } from '../../search/search.svg'

import SearchResults from 'components/shared/search/SearchResults'

import css from './styles.module.scss'
import { useHasScrolledContext } from '../../HasScrolledProvider'

const cx = classnames.bind(css)

const Mobile = ({
  className,
  home,
  panels,
  button,
  dark,
  customLogo,
  darkMode,
  isMarketplace,
}) => {
  const router = useRouter()

  // Access the current URL
  const currentUrl = router.asPath
  const [visible, setVisible] = useState(false)
  const [searching, setSearching] = useState(false)

  const [hasScrolled] = useHasScrolledContext()

  const toggleVisiblity = () => {
    setVisible(!visible)
  }
  const indices = [
    {
      label: 'Plugins',
      name: 'plugins',
    },
    {
      label: 'Providers',
      name: 'providers',
    },
    {
      label: 'Dev docs & User Docs',
      name: 'documentation',
    },
    {
      label: 'Blog Posts',
      name: 'blog_posts',
    },
    {
      label: 'Integrations',
      name: 'integrations',
    },
    {
      label: 'Partners',
      name: 'partners',
    },
  ]

  return (
    <>
      <div className={cx(className, css.Mobile)}>
        <HeaderBackground darkMode={darkMode} />
        <Layout type="maxWidth" className={cx(className, { dark })}>
          <Layout className={css.nav} type="wrapper" tag="nav">
            <Logo
              className={isMarketplace ? css.marketplaceLogo_mobile : css.logo}
              customLogo={customLogo}
              dark={hasScrolled || dark}
              headerDarkMode={darkMode}
              hasScrolled={hasScrolled && !darkMode}
            />
            <div onClick={() => setSearching(true)} className={css.search}>
              <Search className={css.icon} />
            </div>
            <Button
              buttonClassName={css.burgerButton}
              theme="white"
              tagName="button"
            >
              <BurgerIcon
                onClick={toggleVisiblity}
                className={css.burgerIcon}
              />
            </Button>
          </Layout>
        </Layout>
      </div>

      <Overlay
        className={cx(css.overlay)}
        visible={visible}
        toggleVisiblity={toggleVisiblity}
        home={home}
        panels={panels}
        button={button}
      />
      {searching && (
        <SearchResults
          indices={indices}
          closePopin={() => setSearching(false)}
        />
      )}
    </>
  )
}

export default Mobile
