import React from 'react'
import delve from 'dlv'
import Header from 'components/shared/Header'
import Footer from 'components/shared/Footer'
import classnames from 'classnames/bind'

import {
  Seo,
  SeoProvider,
  Microdata,
  SponsoredScript,
} from 'components/shared/Seo'
import { useRouter } from 'next/router'
import useHubspotChat from 'src/hooks/useHubspotChat'
import css from './styles.module.scss'
import { useHasStickyBarProviderContext } from '../HasStickyBarProvider'

const cx = classnames.bind(css)

function Page({
  header,
  footer,
  logoPopin,
  settings,
  seo,
  children,
  pageSeo,
  forwardRef,
  darkMode,
  githubStars,
}) {
  const { asPath } = useRouter()
  const dark = delve(settings, 'theme') !== 'light'
  const isBlank = delve(settings, 'blank')
  const customLogo = delve(settings, 'logo')
  const microdata = delve(settings, 'microdata')
  const sponsoredScript = delve(settings, 'sponsoredScript', false)
  const isMarketplace = delve(settings, 'isMarketplace', false)

  const [hasStickyBar] = useHasStickyBarProviderContext()

  // Check if hubspot chat is available on each page change
  useHubspotChat()

  return (
    <SeoProvider defaultSeo={seo} pageSeo={pageSeo}>
      <div
        ref={forwardRef}
        className={cx(css.Page, {
          hasStickyBar,
          darkMode,
          cloudPage: asPath == '/cloud' ? true : false,
        })}
      >
        {/* Temporary condition to not override users custom SEO. See src/pages/users/[slug].js line 13. */}
        {children?.props?.userBlogPosts ? <></> : <Seo />}

        <Microdata />
        {microdata && <Microdata data={microdata} prepareStructure={false} />}
        {header && !isBlank && (
          <Header
            logoPopin={logoPopin}
            dark={dark}
            customLogo={customLogo}
            {...header}
            darkMode={darkMode}
            isMarketplace={isMarketplace}
            githubStars={githubStars}
          />
        )}

        {isBlank && (
          <Header
            logoPopin={logoPopin}
            dark={dark}
            darkMode={darkMode}
            githubStars={githubStars}
            isBlank={isBlank}
          />
        )}

        <section key={`page-${asPath}`}>
          {sponsoredScript && <SponsoredScript />}
          {children}
        </section>
        {footer && !isBlank && asPath !== '/launch-week' && (
          <Footer {...footer} darkMode={darkMode} />
        )}
      </div>
    </SeoProvider>
  )
}

Page.defaultProps = {
  header: {},
  footer: {},
}

export default Page
