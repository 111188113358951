import React from 'react'
import classnames from 'classnames/bind'
import { useHasStickyBarProviderContext } from '../HasStickyBarProvider'

import Desktop from './Desktop'
import Mobile from './Mobile'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const Header = (props) => {
  const [hasStickyBar] = useHasStickyBarProviderContext()
  const screen = props.isMarketplace
    ? {
        small: css.marketplaceSmallScreenNav,
        large: css.marketplaceLargeScreenNav,
      }
    : { small: css.smallScreenNav, large: css.largeScreenNav }

  return (
    <>
      <Mobile {...props} className={cx(screen.small, { hasStickyBar })} />
      <Desktop {...props} className={cx(screen.large, { hasStickyBar })} />
    </>
  )
}

export default Header
