import classnames from 'classnames/bind'
import Image from 'next/image'
import React from 'react'

import Link from 'components/shared/Link'
import { Text } from 'components/typo'
import SmallArrow from 'icons/SmallArrow'

import typo from 'components/typo/typography.module.scss'
import styles from './styles.module.scss'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const LinksItem = ({
  id,
  hoverLinkId,
  className,
  icons,
  link,
  onMouseEnter,
  onMouseLeave,
  onLinkClick,
}) => {
  return (
    <li
      key={id}
      className={cx(css.link, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link key={id} {...link} onClick={onLinkClick}>
        <div className={css.linkItemWrapper}>
          {icons && icons.length > 0 && (
            <Image
              src={`${
                process.env.API_URL + icons[0]?.media?.data?.attributes?.url
              }`}
              alt={
                icons?.[0]?.media?.data?.attributes?.alternativeText ||
                icons?.[0]?.media?.data?.attributes?.name?.split('/').pop()
              }
              width={32}
              height={32}
            />
          )}
          <div className={css.linkItemTextWrapper}>
            <div className={css.linkTitleWrapper}>
              <Text
                className={css.linkTitle}
                tag="span"
                size="small"
                theme="grey-900"
              >
                {link.text}
              </Text>
              <SmallArrow
                className={css.linkTitleArrow}
                theme={hoverLinkId === id ? 'blue-500' : 'white'}
              />
              {/* {hoverLinkId === id && <SmallArrow className={css.linkTitleArrow} theme="blue-500" />} */}
              {/* <span className={css.linkLoadingText}>Coming soon...</span> */}
            </div>
            <Text className={css.linkSummary} tag="span" size="smaller">
              {link.summary}
            </Text>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default LinksItem
