import React, { useRef, useEffect } from 'react'
import classnames from 'classnames/bind'
import delve from 'dlv'

import Link from 'components/shared/Link'
import Image from 'components/shared/Image'

import useTimeline from 'hooks/useTimeline'
import useOnMouseEnter from 'hooks/useOnMouseEnter'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IconLink = ({ big, link, icon, hoverIcon }) => {
  const iconRef = useRef()
  const hoverIconRef = useRef()

  const [mouseIsHover, onMouseEnter, onMouseLeave] = useOnMouseEnter(false)

  const tl = useTimeline({ paused: true }, (tl) => {
    if (!hoverIcon) return

    tl.fromTo(
      iconRef.current,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.4,
      },
      0
    )
    tl.fromTo(
      hoverIconRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.4,
      },
      0
    )
  })

  useEffect(() => {
    mouseIsHover ? tl.play() : tl.reverse()
  }, [mouseIsHover])

  const href = delve(link, 'href')
  const contentType = delve(link, 'contentType')

  if (!href) return null

  return (
    <li
      className={cx(css.IconLink, { big })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onMouseEnter}
      onBlur={onMouseLeave}
    >
      <Link aria-label={link.text} className={css.link} href={href} contentType={contentType}>
        {icon && (
          <div className={css.iconWrapper} ref={iconRef}>
            <Image
              className={css.icon}
              forcedRatio={1}
              fit="contain"
              {...icon}
            />
          </div>
        )}
        {hoverIcon && (
          <div className={css.hoverIconWrapper} ref={hoverIconRef}>
            <Image
              className={css.icon}
              forcedRatio={1}
              fit="contain"
              {...hoverIcon}
            />
          </div>
        )}
      </Link>
    </li>
  )
}

IconLink.defaultProps = {}

export default IconLink
